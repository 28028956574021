import { graphql } from 'gatsby';
import parse from 'html-react-parser';
import React, { useEffect, useState } from 'react';
import { Navigation, Pagination, Thumbs } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { v4 as uuidv4 } from 'uuid';

const ContactListing = ({ module }) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [className, setClassName] = useState('');

  useEffect(() => {
    const generatedClassName = uuidv4();
    setClassName(generatedClassName);
  }, []);

  const handleNextClickStay = () => {
    const element = document.querySelector(`#id${className} .swiper`);
    if (element) {
      const swiper = element.swiper;
      swiper.slideNext();
    }
  };

  const handlePrevClickStay = () => {
    const element = document.querySelector(`#id${className} .swiper`);
    if (element) {
      const swiper = element.swiper;
      swiper.slidePrev();
    }
  };

  return (
    <section className={`contact-list ${module.extraClass}`}>
      <div className="container-fluid-md">
        <div
          className={`bg-white lg:px-120 desktop2:px-60 px-60 py-50 text-center`}
        >
          <div className="ct-list-info">
            <h2>{module.heading}</h2>
            <p className="lg:px-[7rem]">{module.subheading}</p>
            {module.contactDetails && parse(module.contactDetails)}
          </div>
          {module.members && (
            <div
              id={`id${className}`}
              className={`ct-list-slider relative lg:pt-80 pt-40 lg:px-100`}
            >
              <Swiper
                loop={true}
                slidesPerView={3}
                navigation={true}
                lazy={true}
                speed={1000}
                pagination={{
                  type: 'progressbar',
                }}
                thumbs={{ swiper: thumbsSwiper }}
                modules={[Navigation, Pagination, Thumbs]}
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                  },
                  640: {
                    slidesPerView: 2,
                    spaceBetween: 50,
                  },
                  768: {
                    spaceBetween: 20,
                  },
                  1024: {},
                }}
              >
                {module.members.map((item, i) => {
                  return (
                    <SwiperSlide key={i}>
                      <div className="card-bx lg:px-[25px]">
                        <img
                          src={item?.image.imgixImage.fluid.srcWebp}
                          alt={item?.image.altText}
                          width="363"
                          height="363"
                          className="rounded-[100%]"
                          loading="lazy"
                        />
                        <div className="card-bx-info pt-20">
                          <h6 className="text-18 text-black-200 font-body">
                            {item.heading}
                          </h6>
                          <span className="text-14 text-dark">
                            {item.subheading}
                          </span>
                        </div>
                      </div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
              <div className="swiper-arrow flex items-center justify-between w-full top-50-per left-0 translate-y-minus_50 px-20 lgscreen:px-0">
                <a
                  className="button-prev cursor-pointer"
                  onClick={() => {
                    handlePrevClickStay();
                  }}
                ></a>
                <a
                  className="button-next cursor-pointer"
                  onClick={() => {
                    handleNextClickStay();
                  }}
                ></a>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default ContactListing;
export const ContactListingFragment = graphql`
  fragment ContactListingFragment on WpPage_Flexiblecontent_FlexibleContent_ContactListing {
    # content
    contactDetails
    heading
    fieldGroupName
    subheading
    members {
      fieldGroupName
      heading
      subheading
      image {
        altText
        imgixImage {
          fluid(
            maxWidth: 363
            maxHeight: 363
            imgixParams: { w: 363, h: 363, fit: "crop" }
          ) {
            srcWebp
          }
        }
        uri
        title
      }
    }
    extraClass
    heading
  }
`;
