import { graphql, Link } from 'gatsby';
import parse from 'html-react-parser';
import React from 'react';

const FoundationIntro = ({ module }) => {
  return (
    <>
      <section className="zigzag lg:py-60 py-30 lg:mt-0 mt-30 zigzag-leftalign">
        <div className="container-fluid">
          <div className="flex flex-wrap lgscreen:flex-col-reverse items-center">
            <div className="lg:w-6/12 w-full">
              <div className="zigzag-content lg:text-left text-center fade-ani">
                <div className="title-black mb-20 lg:pr-100">
                  <img
                    className="lgscreen:m-auto"
                    src={module?.logo?.mediaItemUrl}
                    alt={module?.logo?.altText}
                  />

                  <h2>{module.heading}</h2>
                </div>
                {module.content && (
                  <div className="content">{parse(module.content)}</div>
                )}
                <div className="btn-custom mt-40">
                  <Link
                    to={module.cta.url}
                    target={module.cta.target}
                    className="button button-transparent"
                  >
                    {module.cta.title}
                  </Link>
                  <Link
                    to={module.additionalCta.url}
                    target={module.additionalCta.target}
                    className="link-btn"
                  >
                    {module.additionalCta.title}
                  </Link>
                </div>
              </div>
            </div>
            <div className="lg:w-6/12 swipe-img w-full fade-img">
              <div className="zigzag-video relative">
                <video
                  poster={module.image.mediaItemUrl}
                  preload="auto"
                  playsInline="playsinline"
                  autoPlay="autoplay"
                  muted="muted"
                  loop="loop"
                >
                  <source src={module.fullVideo} type="video/mp4" />
                  <track kind="captions"></track>
                </video>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FoundationIntro;

export const FoundationIntroFragment = graphql`
  fragment FoundationIntroFragment on WpPage_Flexiblecontent_FlexibleContent_FoundationIntro {
    # content
    content
    fieldGroupName
    addVideo
    backgroundVideo
    fullVideo
    heading
    cta {
      target
      title
      url
    }
    image {
      altText
      uri
      mediaItemUrl
      title
    }
    additionalCta {
      target
      title
      url
    }
    logo {
      altText
      uri
      mediaItemUrl
      title
    }
  }
`;
