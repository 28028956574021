import { graphql } from "gatsby";
import React from "react";

const LogoTitle = ({ module }) => {
  return (
    <>
      <section className="page-title lg:py-60 py-30">
        <div className="container-fluid">
          <div className="title text-center lg:max-w-[897px] m-auto title-icon fade-ani">
            <img
              src={module.image.imgixImage.url}
              alt={module.image.altText}
              className="m-auto mb-15 w-45"
              loading="eager"
              width="45"
              height="24"
            />
            <h4>{module.heading}</h4>
          </div>
        </div>
      </section>
    </>
  );
};

export default LogoTitle;

export const LogoTitleFragment = graphql`
  fragment LogoTitleFragment on WpPage_Flexiblecontent_FlexibleContent_LogoTitle {
    # content
    fieldGroupName
    heading
    image {
      altText
      uri
      imgixImage {
        url(
          imgixParams: { w: 45, h: 24, dpr: 2, q: 100 })
      }
      title
    }
  }
`;
