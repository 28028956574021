import { graphql } from 'gatsby';
import parse from 'html-react-parser';
import React from 'react';

const HomeIntro = ({ module }) => {
  return (
    <>
      <section className="overlay-content lg:py-60 py-30">
        <div className="container-fluid-md">
          <div className="flex flex-wrap items-center">
            <div className="lg:w-7/12 w-full max-w-[730px] max-h-[555px]">
              {module.videoUrl === null ? (
                <img
                  src={module.image.imgixImage.fluid.srcWebp}
                  alt={module.image ? module.image.altText : ''}
                  className="m-auto"
                  loading="lazy"
                />
              ) : (
                <div className="zigzag-video">
                  <video
                    poster={module.image.mediaItemUrl}
                    preload="auto"
                    playsInline="playsinline"
                    autoPlay="autoplay"
                    muted="muted"
                    loop="loop"
                  >
                    <source src={module.videoUrl} />
                    <track kind="captions"></track>
                  </video>
                </div>
              )}
            </div>
            <div className="lg:w-5/12 w-full overlay-content-mobile">
              <div className="content bg-white lg:px-50 lg:py-80 px-30 py-40 relative z-9 lg:ml-minus-30 lg:top-0 top-[-30px] shadow-card laptop:py-30 mdscreen:text-center">
                <div className="fade-ani">
                  {module.content && parse(module.content)}
                  <div className="content-btm mt-30">
                    <img
                      src={module.signature.imgixImage.fluid.srcWebp}
                      alt={module.image ? module.image.altText : ''}
                      loading="lazy"
                      className="mb-10 mdscreen:m-auto"
                    />

                    <div className="text-10 text-black-200 text-opacity-60">
                      {module.signatureText && parse(module.signatureText)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeIntro;

export const HomeIntroFragment = graphql`
  fragment HomeIntroFragment on WpPage_Flexiblecontent_FlexibleContent_HomeIntro {
    # content
    fieldGroupName
    signatureText
    content
    videoUrl
    image {
      altText
      mediaItemUrl
      uri
      imgixImage {
        fluid(
          maxWidth: 700
          maxHeight: 577
          imgixParams: { w: 700, h: 577, fit: "crop" }
        ) {
          srcWebp
        }
      }
      title
    }
    mobileImage {
      altText
      uri
      mediaItemUrl
      title
    }
    signature {
      altText
      mediaItemUrl
      imgixImage {
        fluid(maxWidth: 130, maxHeight: 29, imgixParams: { w: 130, h: 29 }) {
          srcWebp
        }
      }
      uri
      title
    }
  }
`;
