import { graphql, Link } from "gatsby";
import React, { useEffect, useState } from "react";

const HomepagePropertyListing = ({ module }) => {
  const [mapData, setMapData] = useState([]);
  const [dataList, setDataList] = useState(module.accommodation);

  const removeItem = (i) => {
    let data = mapData;
    data = delete data[i + 1];
    setMapData((mapData) => ({ ...mapData }));
  };

  const updateItem = (i) => {
    let data = mapData;
    data[i + 1] = i + 1;
    setMapData((mapData) => ({ ...mapData }));
  };

  useEffect(() => {
    setDataList(module.accommodation);
  }, [mapData]);

  return (
    <>
      <section className="full-bx-slider remove-grab full-bx-slider-content home-property-listing bg-white lg:py-60 py-60 lg:pb-30">
        <div className="container-fluid smscreen2:px-0">
          <div className="flex flex-wrap mx-minus-30 smscreen2:mx-minus-0">
            {dataList.length > 0 &&
              dataList.map((item, i) => {
                return (
                  <div key={i} className="lg:w-6/12 w-full lg:px-30 lg:mb-50 mb-40 px-0">
                    <div className="card-bx text-left">
                      <div className="relative">
                      <Link
                            to={`${item?.uri}`}
                          >
                          <img
                            src={
                              mapData[i + 1]
                                ? item.propertyContent.mapImage.imgixImage.url
                                : item.featuredImage?.node.imgixImage.fluid.srcWebp
                            }
                            width="640"
                            height="426"
                            className="w-full"
                            alt={item.featuredImage?.node?.altText || ""}
                            loading="lazy"
                          />
                      </Link>
                      </div>
                      <div className="content pt-30 flex flex-wrap smscreen2:flex-col justify-between pl-0 lgscreen:px-20 lgscreen:pt-15">
                        <div>
                          <Link
                            to={`${item?.uri}`}
                          >
                            <span className="uppercase text-dark text-11 font-400 font-preheaders mb-0">
                              {item.propertyContent.subheading}
                            </span>
                            <h4>{item.propertyContent.heading}</h4>
                          </Link>
                        </div>
                        <div className="flex flex-wrap items-center pt-10 gap-x-8">
                          <Link
                            to={`${item?.uri}`}
                            className="button button-transparent"
                          >
                            EXPLORE
                          </Link>
                          {mapData[i + 1] ? (
                            <button
                              onClick={() => removeItem(i)}
                              className="link-btn cursor-pointer"
                            >
                              <span className="map-link">VIEW IMAGE</span>
                            </button>
                          ) : (
                            <button
                              onClick={() => updateItem(i)}
                              className="link-btn cursor-pointer"
                            >
                              <span className="map-link">VIEW MAP</span>
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </section>
    </>
  );
};

export default HomepagePropertyListing;

export const HomepagePropertyListingFragment = graphql`
  fragment HomepagePropertyListingFragment on WpPage_Flexiblecontent_FlexibleContent_HomepagePropertyListing {
    # content
    fieldGroupName
    heading
    accommodation {
      ... on WpProperty {
        id
        title
        uri
        excerpt
        propertyContent {
          experienceContent
          content
          heading
          subheading
          mapImage {
            altText
            title
            sourceUrl
            imgixImage {
              url(
                imgixParams: { w: 645, h: 385, q: 95, fit: "crop" })
            }
            uri
          }
        }
        featuredImage {
          node {
            altText
            sourceUrl
            imgixImage {
              fluid(maxWidth: 640, imgixParams: {w: 640, h: 426, ar: "320:213", fit: "crop"}) {
                srcWebp
              }
            }
          }
        }
      }
    }
  }
`;