import loadable from '@loadable/component';
import { graphql } from 'gatsby';
import React from 'react';
import Accordionlist from '../components/Accordionlist';
import AwardsRelationship from '../components/AwardsRelationship';
import BlogLoop from '../components/BlogLoop';
import CardCarousel from '../components/CardCarousel';
import ColumnListing from '../components/ColumnListing';
import ContactListing from '../components/ContactListing';
import ContactTabs from '../components/ContactTabs';
import Form from '../components/Form';
import FoundationBanner from '../components/FoundationBanner';
import FoundationIntro from '../components/FoundationIntro';
import FoundationLoop from '../components/FoundationLoop';
import FullWidthBanner from '../components/FullWidthBanner';
import Gallery from '../components/Gallery';
import GalleryListing from '../components/GalleryListing';
import GeneralContent from '../components/GeneralContent';
import GlobalGallery from '../components/GlobalGallery';
import HeadingWithEmbed from '../components/HeadingWithEmbed';
import HomeIntro from '../components/HomeIntro';
import HomepagePropertyListing from '../components/HomepagePropertyListing';
import ImageContent from '../components/ImageContent';
import ImageContentWithTab from '../components/ImageContentWithTab';
import Instagram from '../components/Instagram';
import IntroContent from '../components/IntroContent';
import ItinerariesLoop from '../components/ItinerariesLoop';
import LogoTitle from '../components/LogoTitle';
import MicrositeIntroContent from '../components/MicrositeIntroContent';
import OffersLoop from '../components/OffersLoop';
import PressLoop from '../components/PressLoop';
import PropertyListing from '../components/PropertyListing';
import RelatedCards from '../components/RelatedCards';
import Search from '../components/Search';
import StayWithUs from '../components/StayWithUs';
import SustainabilityRelationship from '../components/SustainabilityRelationship';
import TabbedCardCarousel from '../components/TabbedCardCarousel';
import TabbedCardRelationship from '../components/TabbedCardRelationship';
import TestimonialListing from '../components/TestimonialListing';
import UspListing from '../components/UspListing';
import WhatsOnListing from '../components/WhatsOnListing';

const DonationForm = loadable(() => import('../components/DonationForm'));
const RatesSection = loadable(() => import('../components/RatesSection'));

const Main = (props) => {
  const modules = props.modules.flexibleContent;
  const { location } = props;
  const components = {
    FullWidthBanner,
    LogoTitle,
    HomeIntro,
    IntroContent,
    HomepagePropertyListing,
    ImageContent,
    FoundationBanner,
    RelatedCards,
    ColumnListing,
    TestimonialListing,
    StayWithUs,
    AwardsRelationship,
    OffersLoop,
    Gallery,
    CardCarousel,
    FoundationIntro,
    BlogLoop,
    WhatsOnListing,
    FoundationLoop,
    ImageContentWithTab,
    ContactListing,
    HeadingWithEmbed,
    Form,
    UspListing,
    PressLoop,
    TabbedCardCarousel,
    GeneralContent,
    PropertyListing,
    TabbedCardRelationship,
    GalleryListing,
    SustainabilityRelationship,
    ItinerariesLoop,
    Accordionlist,
    Search,
    ContactTabs,
    DonationForm,
    GlobalGallery,
    RatesSection,
    MicrositeIntroContent,
    Instagram,
  };

  return (
    <>
      {modules
        ? modules.map((module, i) => {
            const moduleName = module.__typename.replace(
              'WpPage_Flexiblecontent_FlexibleContent_',
              ''
            );
            return (
              components[moduleName] &&
              React.createElement(components[moduleName], {
                key: i,
                module,
                location,
                i,
                modules: modules,
              })
            );
          })
        : null}
    </>
  );
};

export default Main;

export const MainFragment = graphql`
  fragment MainFragment on WpPage {
    flexibleContent {
      flexibleContent {
        __typename
        ...FullWidthBannerFragment
        ...HomepagePropertyListingFragment
        ...LogoTitleFragment
        ...HomeIntroFragment
        ...IntroContentFragment
        ...ImageContentFragment
        ...FoundationBannerFragment
        ...RelatedCardsFragment
        ...ColumnListingFragment
        ...TestimonialListingFragment
        ...GalleryFragment
        ...CardCarouselFragment
        ...FoundationIntroFragment
        ...HeadingWithEmbedFragment
        ...ContactListingFragment
        ...ImageContentWithTabFragment
        ...UspListingFragment
        ...PressLoopFragment
        ...FoundationLoopFragment
        ...TabbedCardCarouselFragment
        ...GeneralContentFragment
        ...PropertyListingFragment
        ...BlogLoopFragment
        ...TabbedCardRelationshipFragment
        ...GalleryListingFragment
        ...MainOffersLoopPropertyFragment
        ...AwardsRelationshipFragment
        ...SustainabilityRelationshipFragment
        ...ItinerariesLoopFragment
        ...AccordionlistFragment
        ...ContactTabsFragment
        ...SearchFragment
        ...DonationFormFragment
        ...GlobalGalleryFragment
        ...RatesSectionFragment
        ...MicrositeIntroContentMainFragment
        ...WhatsOnListingFragment
        ...PageInstagramFragment
      }
    }
  }
`;
