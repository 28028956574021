import { graphql, Link } from 'gatsby';
import parse from 'html-react-parser';
import React from 'react';

const ItinerariesLoop = ({ module }) => {
  const data = module?.itineraries || [];
  return (
    <section className={`lg:py-60 py-30 img-grid landscape lg-mb-60 mb-30`}>
      <div className="container-fluid">
        <div className="flex flex-wrap lg:mx-minus-20 lg:gap-y-20 gap-y-10 ">
          {data?.length > 0 &&
            data?.map((item, index) => {
              return (
                <div
                  className={`lg:w-6/12 w-full lg:px-20 fade-img`}
                  key={item?.id || index.toString()}
                >
                  <Link to={item.uri}>
                    <img
                      src={item?.featuredImage.node.imgixImage.fluid.srcWebp}
                      alt={item?.featuredImage.node.altText}
                      width="890"
                      height="512"
                      loading="lazy"
                    />
                  </Link>
                  {item?.itineraryContent?.tripContent?.length > 0 && (
                    <div className="img-content pt-30 max-w-540">
                      <Link to={item.uri}>
                        {item.itineraryContent.tripContent[0]?.preheading && (
                          <span className="text-13 text-dark font-400 uppercase leading-22 inline-block font-preheaders">
                            {item.itineraryContent.tripContent[0].preheading}
                          </span>
                        )}
                        {item.itineraryContent.tripContent[0]?.heading && (
                          <h4 className="text-black-200">
                            {item.itineraryContent.tripContent[0]?.heading}
                          </h4>
                        )}
                        {item.itineraryContent.tripContent[0]?.subheading && (
                          <span className="text-12 text-dark font-400 uppercase leading-22 mb-10 block">
                            {item.itineraryContent.tripContent[0]?.subheading}
                          </span>
                        )}
                      </Link>
                      {item.itineraryContent.tripContent[0]?.description && (
                        <div className="content text-sm">
                          {parse(
                            item.itineraryContent.tripContent[0]?.description
                          )}
                        </div>
                      )}
                      <div className="mt-20  cursor-pointer smscreen:mt-10">
                        <Link to={item.uri} className="link-btn">
                          Explore
                        </Link>
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
        </div>
      </div>
    </section>
  );
};

export default ItinerariesLoop;

export const ItinerariesLoopFragment = graphql`
  fragment ItinerariesLoopFragment on WpPage_Flexiblecontent_FlexibleContent_ItinerariesLoop {
    # content
    heading
    itineraries {
      ... on WpItinerary {
        id
        uri
        title
        featuredImage {
          node {
            altText
            imgixImage {
              fluid(
                maxWidth: 890
                maxHeight: 512
                imgixParams: { w: 890, h: 512, fit: "crop" }
              ) {
                srcWebp
              }
            }
          }
        }
        itineraryContent {
          tripContent {
            ... on WpItinerary_Itinerarycontent_TripContent_IntroContent {
              description
              fieldGroupName
              heading
              preheading
              subheading
              cta {
                target
                title
                url
              }
            }
          }
        }
      }
    }
  }
`;
