import { graphql, Link } from 'gatsby';
import parse from 'html-react-parser';
import React, { useState } from 'react';
import { Navigation, Pagination, Thumbs } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

const PropertyListing = ({ module }) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [tabOption, setTabOption] = useState(module.propertyTabs[0].tabHeading);

  const handleNextClickStay = () => {
    const swiper = document.querySelector('.thumbSlider').swiper;
    swiper.slideNext();
  };
  const handlePrevClickStay = () => {
    const swiper = document.querySelector('.thumbSlider').swiper;
    swiper.slidePrev();
  };
  return (
    <>
      <section className="full-bx-slider full-bx-slider-content nnnnn property-slider bg-white lg:py-120 py-60">
        <div className="title-black text-center px-20">
          <h2>{module.heading}</h2>
        </div>
        <div className="max-w-[851px] m-auto my-50 smscreen2:mb-30 setThumbSlider">
          <ul className="flex justify-center slider-tabs">
            {module.propertyTabs &&
              module.propertyTabs.map((item, i) => {
                return (
                  <li
                    onClick={() => {
                      setTabOption(item.tabHeading);
                    }}
                    className={`${
                      item.tabHeading === tabOption ? 'active' : ''
                    } uppercase cursor-pointer text-15 text-dark font-400`}
                  >
                    {item.tabHeading}
                  </li>
                );
              })}
          </ul>
        </div>

        {module.propertyTabs &&
          module.propertyTabs.map((item, i) => {
            return (
              tabOption === item.tabHeading && (
                <Swiper
                  className="thumbSlider"
                  loop={true}
                  centeredSlides={true}
                  navigation={true}
                  thumbs={{ swiper: thumbsSwiper }}
                  speed={1000}
                  modules={[Navigation, Pagination, Thumbs]}
                  breakpoints={{
                    0: {
                      slidesPerView: 1.5,
                      spaceBetween: 20,
                    },
                    640: {
                      slidesPerView: 1.5,
                      spaceBetween: 20,
                    },
                    768: {
                      slidesPerView: 1.5,
                      spaceBetween: 20,
                    },
                    1024: {
                      slidesPerView: 1.5,
                      spaceBetween: 20,
                    },
                  }}
                >
                  {item.propertyTabContent.map((item1, i) => {
                    return (
                      <SwiperSlide>
                        <div className="card-bx text-center">
                          <div className="relative">
                            <img
                              src={item1.image.imgixImage.fluid.srcWebp}
                              alt="slider"
                              width="796"
                              height="517"
                              loading="lazy"
                            />
                            <div className="swiper-arrow  flex items-center justify-between w-full top-50-per translate-y-minus_50 px-20">
                              <a
                                className="button-prev cursor-pointer"
                                onClick={handlePrevClickStay}
                              ></a>
                              <a
                                className="button-next cursor-pointer"
                                onClick={handleNextClickStay}
                              ></a>
                            </div>
                          </div>
                          <div className="content pt-30 max-w-500 m-auto mdscreen:px-20">
                            <h3>{item1.heading}</h3>
                            {item1.content && parse(item1.content)}
                            <div className="flex flex-wrap items-center pt-10 mt-20 gap-x-8">
                              {item1.cta && (
                                <Link
                                  to={item1.cta.url}
                                  className="button button-transparent"
                                >
                                  {item1.cta.title}
                                </Link>
                              )}
                              {item1.additionalCta && (
                                <Link
                                  to={item1.additionalCta.url}
                                  className="link-btn "
                                >
                                  <span className="map-link">
                                    {item1.additionalCta.title}
                                  </span>
                                </Link>
                              )}
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              )
            );
          })}
      </section>
    </>
  );
};

export default PropertyListing;

export const PropertyListingFragment = graphql`
  fragment PropertyListingFragment on WpPage_Flexiblecontent_FlexibleContent_PropertyListing {
    # content
    fieldGroupName
    propertyTabs {
      tabHeading
      propertyTabContent {
        subheading
        image {
          altText
          uri
          title
          imgixImage {
            fluid(
              maxWidth: 796
              maxHeight: 517
              imgixParams: { w: 890, h: 647, fit: "crop" }
            ) {
              srcWebp
            }
          }
        }
        heading
        fieldGroupName
        cta {
          url
          title
          target
        }
        content
        additionalCta {
          target
          title
          url
        }
      }
    }
  }
`;
