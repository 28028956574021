import { graphql, Link } from 'gatsby';
import parse from 'html-react-parser';
import React, { useEffect, useState } from 'react';
import { Navigation, Pagination, Thumbs } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

const TabbedCardRelationship = ({ module }) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [data, setData] = useState([]);
  const [isImage, setisImage] = useState(true);
  const [isImageIndex, setisImageIndex] = useState('');
  const [isSelecteSlide, setisSelecteSlide] = useState(
    module.accommodation[0].title
  );

  const handleNextClickStay = () => {
    setisImageIndex('');
    setisImage(true);
    const swiper = document.querySelector(
      '.full-bx-slider .propertySlider'
    ).swiper;
    swiper.slideNext();
  };
  const handlePrevClickStay = () => {
    setisImageIndex('');
    setisImage(true);
    const swiper = document.querySelector(
      '.full-bx-slider .propertySlider'
    ).swiper;
    swiper.slidePrev();
  };

  useEffect(() => {
    setData(module);
  }, [isImage]);

  return (
    <>
      <section
        className={`full-bx-slider  full-bx-slider-content property-slider bg-white lg:py-120 py-60 ${module.extraClass}`}
      >
        <div className="title-black text-center px-20">
          <h2>{module.heading}</h2>
        </div>
        <div>
          <div className="tabbed-desktop lg:block mdscreen:hidden">
            <div className="max-w-[1000px] m-auto my-40 smscreen2:mb-30 setThumbSlider">
              <Swiper
                onSwiper={setThumbsSwiper}
                loop={false}
                spaceBetween={0}
                slidesPerView={4}
                allowTouchMove={true}
                freeMode={true}
                watchSlidesProgress={true}
                modules={[Navigation, Thumbs]}
                className="mySwiper"
              >
                {data?.accommodation?.length > 0 &&
                  module.accommodation.map((item, i) => {
                    return (
                      <SwiperSlide>
                        <span className="uppercase text-15 text-dark font-400">
                          {item.title}
                        </span>
                      </SwiperSlide>
                    );
                  })}
              </Swiper>
            </div>
            {data?.accommodation?.length > 0 && (
              <Swiper
                className="propertySlider"
                loop={true}
                slidesPerView={1.8}
                spaceBetween={20}
                allowTouchMove={true}
                centeredSlides={true}
                navigation={true}
                thumbs={{ swiper: thumbsSwiper }}
                speed={1000}
                modules={[Navigation, Pagination, Thumbs]}
              >
                {module.accommodation.map((item, i) => {
                  return (
                    <SwiperSlide key={item.id}>
                      <div className="card-bx text-center">
                        <div className="relative">
                          <img
                            src={
                              !isImage && isImageIndex === i
                                ? item.propertyContent.mapImage.imgixImage.fluid
                                    .srcWebp
                                : item.featuredImage?.node.imgixImage.fluid
                                    .srcWebp
                            }
                            alt={item?.image.altText}
                            width="685"
                            className="w-full"
                            height="411"
                            loading="lazy"
                          />
                          <div className="swiper-arrow  flex items-center justify-between w-full top-50-per translate-y-minus_50 px-20">
                            <a
                              className="button-prev cursor-pointer"
                              onClick={handlePrevClickStay}
                            ></a>
                            <a
                              className="button-next cursor-pointer"
                              onClick={handleNextClickStay}
                            ></a>
                          </div>
                        </div>
                        <div className="content pt-20 max-w-500 m-auto">
                          <h3>{item.propertyContent.heading}</h3>
                          <span className="uppercase text-dark text-11 font-400 font-preheaders mb-0">
                            {item.propertyContent.subheading}
                          </span>
                          {item.propertyContent.content &&
                            parse(item.propertyContent.content)}
                          <div className="flex flex-wrap items-center pt-10 mt-20 gap-x-8">
                            <Link
                              to={item?.uri}
                              className="button button-transparent"
                            >
                              EXPLORE
                            </Link>
                            {!isImage ? (
                              <a
                                onClick={() => {
                                  setisImage(true);
                                }}
                                className="link-btn cursor-pointer "
                              >
                                <span className="map-link">VIEW ON IMAGE</span>
                              </a>
                            ) : (
                              <a
                                onClick={() => {
                                  setisImage(false);
                                  setisImageIndex(i);
                                }}
                                className="link-btn cursor-pointer "
                              >
                                <span className="map-link">VIEW ON MAP</span>
                              </a>
                            )}
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            )}
          </div>
          <div className="tabbed-mobile lg:hidden mdscreen:block">
            <div className="max-w-[1000px] m-auto my-50 text-left px-20 mt-0 smscreen2:mb-30 setThumbSlider">
              <select
                className="outline-none bg-white text-left w-full border-b-1 pb-10 border-dark border-opacity-40"
                onChange={(e) => {
                  setisImage(true);
                  setisImageIndex('');
                  setisSelecteSlide(e.target.value);
                }}
              >
                {data?.accommodation?.length > 0 &&
                  data.accommodation.map((item, i) => {
                    return <option value={item.title}>{item.title}</option>;
                  })}
              </select>
            </div>
            {data?.accommodation?.length > 0 &&
              data.accommodation.map((item, i) => {
                return (
                  isSelecteSlide === item.title && (
                    <div className="card-bx text-center">
                      <div className="relative">
                        <img
                          src={
                            !isImage && isImageIndex === i
                              ? item.propertyContent.mapImage.imgixImage.fluid
                                  .srcWebp
                              : item.featuredImage?.node.imgixImage.fluid
                                  .srcWebp
                          }
                          alt={item?.image.altText}
                          width="685"
                          className="w-full"
                          height="411"
                          loading="lazy"
                        />
                        <div className="swiper-arrow  flex items-center justify-between w-full top-50-per translate-y-minus_50 px-20">
                          <a
                            className="button-prev cursor-pointer"
                            onClick={handlePrevClickStay}
                          ></a>
                          <a
                            className="button-next cursor-pointer"
                            onClick={handleNextClickStay}
                          ></a>
                        </div>
                      </div>
                      <div className="content pt-30 max-w-500 m-auto mdscreen:px-20">
                        <h3>{item.propertyContent.heading}</h3>
                        <span className="uppercase text-dark text-11 font-400 font-preheaders mb-0">
                          {item.propertyContent.subheading}
                        </span>
                        {item.propertyContent.content &&
                          parse(item.propertyContent.content)}
                        <div className="flex flex-wrap items-center pt-10 mt-20 gap-x-8">
                          <Link
                            to={item?.uri}
                            className="button button-transparent"
                          >
                            EXPLORE
                          </Link>
                          {!isImage ? (
                            <a
                              onClick={() => {
                                setisImage(true);
                              }}
                              className="link-btn cursor-pointer "
                            >
                              <span className="map-link">VIEW ON IMAGE</span>
                            </a>
                          ) : (
                            <a
                              onClick={() => {
                                setisImage(false);
                                setisImageIndex(i);
                              }}
                              className="link-btn cursor-pointer "
                            >
                              <span className="map-link">VIEW ON MAP</span>
                            </a>
                          )}
                        </div>
                      </div>
                    </div>
                  )
                );
              })}
          </div>
        </div>
      </section>
    </>
  );
};

export default TabbedCardRelationship;

export const TabbedCardRelationshipFragment = graphql`
  fragment TabbedCardRelationshipFragment on WpPage_Flexiblecontent_FlexibleContent_TabbedCardRelationship {
    # content
    fieldGroupName
    heading
    extraClass
    accommodation {
      ... on WpProperty {
        id
        title
        uri
        excerpt
        propertyContent {
          experienceContent
          content
          heading
          subheading
          mapImage {
            altText
            title
            imgixImage {
              fluid(
                maxWidth: 685
                maxHeight: 411
                imgixParams: { w: 685, h: 411, fit: "crop" }
              ) {
                srcWebp
              }
            }
            uri
          }
        }
        featuredImage {
          node {
            altText
            imgixImage {
              fluid(
                maxWidth: 685
                maxHeight: 411
                imgixParams: { w: 685, h: 411, fit: "crop" }
              ) {
                srcWebp
              }
            }
          }
        }
      }
    }
  }
`;
