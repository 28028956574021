import { graphql, Link, useStaticQuery } from 'gatsby';
import parse from 'html-react-parser';
import React, { useState } from 'react';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

const BlogLoop = ({ module }) => {
  const blogLoopData = useStaticQuery(graphql`
    query BlogPostCategories {
      blogPostCategories: allWpCategory {
        nodes {
          name
          id
          slug
        }
      }
      blogPostInterest: allWpBlogInterest {
        nodes {
          name
          id
          slug
        }
      }
      posts: allWpPost(
        filter: { terms: { nodes: { elemMatch: { name: { ne: "Private" } } } } }
        sort: { fields: date, order: DESC }
      ) {
        edges {
          node {
            categories {
              nodes {
                id
                name
                slug
              }
            }
            blogInterest {
              nodes {
                id
                name
                slug
              }
            }
            title
            uri
            excerpt
            featuredImage {
              node {
                altText
                imgixImage {
                  url(imgixParams: { w: 1600, h: 800, q: 95 })
                }
                title
              }
            }
            date(formatString: "DD MMMM YYYY")
            author {
              node {
                name
              }
            }
          }
        }
      }
    }
  `);

  const blogCategories = blogLoopData?.blogPostCategories?.nodes || [];
  const blogInterest = blogLoopData?.blogPostInterest?.nodes || [];
  const posts = blogLoopData?.posts?.edges || [];
  const [postsToDisplay, setPostToDisplay] = useState(posts);
  const pageSize = 7;
  const [limit, setLimit] = useState(pageSize);

  const [searchData, setSearchData] = useState({
    text: '',
  });
  const [barCat, setBarCat] = useState('all');
  const [barGuest, setBarGuest] = useState('all');

  const onCategoryChange = (newCategoryId) => {
    if (newCategoryId === 'all') {
      setPostToDisplay(posts);
    } else {
      setPostToDisplay(
        posts.filter((item) => {
          const categoryIds = item.node.categories.nodes.map(
            (categoryNode) => categoryNode.id
          );
          if (categoryIds.indexOf(newCategoryId) > -1) {
            return true;
          }
          return false;
        })
      );
    }
  };

  const handleNextClickStay = () => {
    const swiper = document.querySelector('.blogLoop-slider .swiper').swiper;
    swiper.slideNext();
  };
  const handlePrevClickStay = () => {
    const swiper = document.querySelector('.blogLoop-slider .swiper').swiper;
    swiper.slidePrev();
  };

  const handleSearchClick = () => {
    let setSearch = searchData.text;
    if (setSearch.length > 0) {
      const catArray = posts.filter((item) => {
        const postTitle = item.node.title.toLowerCase();
        //const postContent = item.node.excerpt.toLowerCase();
        const titleget = !setSearch || postTitle.includes(setSearch);
        // const contentget = !setSearch || postContent.includes(setSearch);
        return titleget; //&& contentget;
      });
      setPostToDisplay(catArray);
    } else {
      setPostToDisplay(posts);
    }
  };

  const handleFilterData = (selectedCat, selectedGuest) => {
    if (selectedCat === 'all' && selectedGuest === 'all') {
      setPostToDisplay(posts);
    }

    if (selectedCat === 'all' && selectedGuest !== 'all') {
      const catArray = posts.filter((item) => {
        const barGuestSlugs = item.node.blogInterest.nodes.map(
          (node) => node.slug
        );
        const isGuestMatch =
          !selectedGuest || barGuestSlugs.includes(selectedGuest);
        return isGuestMatch;
      });
      setPostToDisplay(catArray);
    }

    if (selectedGuest === 'all' && selectedCat !== 'all') {
      const guestArray = posts.filter((item) => {
        const barCategorySlugs = item.node.categories.nodes.map(
          (node) => node.slug
        );
        const isCategoryMatch =
          !selectedCat || barCategorySlugs.includes(selectedCat);
        return isCategoryMatch;
      });
      setPostToDisplay(guestArray);
    }

    if (selectedCat !== 'all' && selectedGuest !== 'all') {
      const allArray = posts.filter((item) => {
        const barCategorySlugs = item.node.categories.nodes.map(
          (node) => node.slug
        );
        const barGuestSlugs = item.node.blogInterest.nodes.map(
          (node) => node.slug
        );
        const isCategoryMatch =
          !selectedCat || barCategorySlugs.includes(selectedCat);
        const isGuestMatch =
          !selectedGuest || barGuestSlugs.includes(selectedGuest);
        return isCategoryMatch && isGuestMatch;
      });
      setPostToDisplay(allArray);
    }
  };

  return (
    <>
      {module.variation === 'Grid' ? (
        <section
          className={`img-grid portrait lg:py-60 py-30 blogLoop-slider ${module.extraClass}`}
        >
          <div className="container-fluid">
            <div className="zigzag lg:py-60 py-30">
              {postsToDisplay.length > 0 &&
                postsToDisplay.slice(0, 1).map((item, i) => (
                  <div className="flex flex-wrap items-center" key={i}>
                    <div className="lg:w-6/12 w-full">
                      <div className="img landscape relative">
                        <Link to={item?.node.uri} className="h-full">
                          <img
                            src={item?.node?.featuredImage?.node.imgixImage.url}
                            alt={item?.node?.featuredImage?.node?.altText || ''}
                            loading="lazy"
                            className="!h-[100%]"
                            width={693}
                            height={450}
                          />
                        </Link>
                        {/* <span className="absolute right-30 top-[35px] px-20 py-10 text-white bg-black-100 bg-opacity-30 rounded-2">
                          Featured Blog
                        </span> */}
                      </div>
                    </div>
                    <div className="lg:w-6/12 w-full">
                      <div className="zigzag-content max-w-[600px] desktop2:max-w-[500px] lgscreen:w-full !pl-80 xlscreen:!pl-40 lgscreen:!pl-0 !pr-0">
                        <h6 className="text-dark font-body text-14 font-400 leading-25 uppercase">
                          {item?.node.date} | {item?.node.author.node.name}
                        </h6>
                        <h4 className="lg:text-32 text-26 lg:leading-39 leading-30 xlscreen:text-28 text-black-300 mdscreen:text-26 mdscreen:leading-30 text-black-200 mt-10">
                          {item?.node.title}
                        </h4>
                        <h6 className="text-gold font-body text-14 font-400 leading-25 mt-5 mb-20">
                          {item.node.blogInterest?.nodes?.length > 0 &&
                            item.node.blogInterest.nodes.map((intrest, j) => (
                              <span key={j}>{intrest.name}</span>
                            ))}
                        </h6>
                        <p>{item?.node.excerpt && parse(item?.node.excerpt)}</p>
                        <Link to={item?.node.uri} className="link-btn mt-30">
                          Explore
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}
            </div>

            <div className="post-list-filter pb-35">
              <div className="flex justify-between lg:gap-x-10 desktop2:gap-8 laptop:gap-5 xlscreen:gap-4 items-center smscreen2:flex-col smscreen2:gap-y-4">
                <div className="lg:w-3/12 w-full flex flex-wrap">
                  <div className="w-6/12 pr-25 lgscreen:pr-10">
                    <div className="sw-info-select relative">
                      <select
                        value={barCat}
                        onChange={(e) => {
                          const selectedValue = e.target.value;
                          setBarCat(selectedValue);
                          handleFilterData(selectedValue, barGuest);
                        }}
                      >
                        <option value="all">Select Property</option>
                        {blogCategories.map((item, i) => {
                          return (
                            <option key={i} value={item.slug}>
                              {item.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  {/* <div className="w-6/12 pl-25 lgscreen:pl-10">
                    <div className="sw-info-select relative">
                      <select
                        value={barGuest}
                        onChange={(e) => {
                          const selectedValue = e.target.value;
                          setBarGuest(selectedValue);
                          handleFilterData(barCat, selectedValue);
                        }}
                      >
                        <option value="all">Select Category</option>
                        {blogInterest.map((item, j) => {
                          return (
                            <option key={j} value={item.slug}>
                              {item.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div> */}
                </div>
                <div className="lg:w-2/12 w-full">
                  <div className="btn-custom w-full btn-search relative">
                    <input
                      type="text"
                      value={searchData.text}
                      onChange={(e) => {
                        setSearchData((prevData) => ({
                          ...prevData,
                          text: e.target.value,
                        }));
                        handleSearchClick();
                      }}
                      onKeyDown={(e) => {
                        setSearchData((prevData) => ({
                          ...prevData,
                          text: e.target.value,
                        }));
                        handleSearchClick();
                      }}
                      className="button button-transparent w-full inline-block hover:bg-transparent hover:border-gray-100 hover:border-opacity-60"
                      placeholder="Search Our Blogs..."
                    />
                    <button
                      onClick={handleSearchClick}
                      className="absolute right-[14px] top-50-per translate-y-minus_50"
                    >
                      <img
                        src="/images/search-icon2.png"
                        className="max-w-20"
                        height="11"
                        width="14"
                        alt=""
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-wrap lg:mx-minus-20 lg:gap-y-20 gap-y-10 ">
              {postsToDisplay.length > 0 &&
                postsToDisplay.slice(1, limit).map((item, index) => {
                  return (
                    <div
                      key={index}
                      className={`lg:w-4/12 w-full lg:px-20 fade-img`}
                    >
                      <div className="img">
                        <Link to={item?.node.uri}>
                          <img
                            src={item?.node?.featuredImage?.node.imgixImage.url}
                            alt={item?.node?.featuredImage?.node?.altText || ''}
                            width="580"
                            height="476"
                            className={`object-cover w-full`}
                            loading="lazy"
                          />
                        </Link>
                      </div>
                      <div className="img-content pt-30 max-w-800">
                        <span className="uppercase text-dark text-13 font-400 leading-24 font-body">
                          {item?.node.date}
                        </span>
                        <Link to={item?.node.uri}>
                          <h4 className="text-black-200 mt-10 mb-20 font-heading text-[28px] font-400 leading-[32px]">
                            {' '}
                            {item?.node.title}
                          </h4>
                        </Link>
                        <span className="feature-list text-gold text-13 font-400 leading-24 font-body">
                          {item.node.blogInterest?.nodes?.length > 0 &&
                            item.node.blogInterest.nodes.map((intrest, j) => (
                              <span key={j}>{intrest.name}</span>
                            ))}
                        </span>
                        <div className="content text-sm mt-15 mb-30">
                          {item?.node.excerpt && parse(item?.node.excerpt)}
                        </div>
                        <Link to={item?.node.uri} className="link-btn">
                          READ MORE
                        </Link>
                      </div>
                    </div>
                  );
                })}
            </div>
            {postsToDisplay?.length > limit && (
              <div className="btn-custom text-center justify-center cursor-pointer lg:mt-80 mt-40">
                <a
                  onClick={() => {
                    setLimit((prevLimit) => prevLimit + pageSize);
                  }}
                  className="button button-transparent bg-[#e2e2e2]"
                >
                  Load More
                </a>
              </div>
            )}

            {postsToDisplay.length == 0 && searchData.text == '' && (
              <div className="text-center justify-center lg:mt-80 mt-40 text-13 font-400 leading-24 font-body">
                Please select another option.
              </div>
            )}
            {postsToDisplay.length == 0 && searchData.text != '' && (
              <div className="text-center justify-center lg:mt-80 mt-40 text-13 font-400 leading-24 font-body">
                Oops! It looks like we couldn't find any blogs matching your
                search. Please try again.
              </div>
            )}
          </div>
        </section>
      ) : (
        <section
          className={`imgBox-slider portrait lg:py-60 py-30 blogLoop-slider ${module.extraClass}`}
        >
          <div className="container-fluid-md pr-0 mdscreen:pl-0">
            <div className="slider-wrapper relative">
              <Swiper
                loop={true}
                slidesPerView={2.2}
                speed={1000}
                navigation={true}
                modules={[Navigation]}
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                    centeredSlides: false,
                    allowTouchMove: true,
                  },
                  640: {
                    slidesPerView: 1.5,
                    spaceBetween: 20,
                  },
                  768: {
                    slidesPerView: 2.2,
                    spaceBetween: 40,
                  },
                  1024: {
                    slidesPerView: 2.2,
                    spaceBetween: 40,
                  },
                }}
              >
                {postsToDisplay.map((item, i) => {
                  return (
                    <SwiperSlide key={i}>
                      <div className="card-bx">
                        <div className="relative">
                          <img
                            src={item?.node?.featuredImage?.node.imgixImage.url}
                            alt={item?.node?.featuredImage?.node.altText || ''}
                            className=""
                            loading="lazy"
                          />
                          <div className="swiper-arrow z-9 flex items-center justify-between w-full top-50-per translate-y-minus_50 px-20">
                            <a
                              className="button-prev cursor-pointer"
                              onClick={handlePrevClickStay}
                            ></a>
                            <a
                              className="button-next cursor-pointer"
                              onClick={handleNextClickStay}
                            ></a>
                          </div>
                        </div>
                        <div className="img-content pt-30 smscreen2:text-center mdscreen:pl-20">
                          <h4 className="text-black-200">{item?.node.title}</h4>
                          {item.categories?.nodes?.length > 0 && (
                            <span className="uppercase text-dark text-11 font-400 mt-15 inline-block font-preheaders">
                              {item.categories?.nodes[0]?.name}
                            </span>
                          )}
                          {item.excerpt && (
                            <div className="content text-sm my-15">
                              {item.excerpt && parse(item.excerpt)}
                            </div>
                          )}
                          <Link to={item?.node.uri} className="link-btn">
                            Read More
                          </Link>
                        </div>
                      </div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default BlogLoop;

export const BlogLoopFragment = graphql`
  fragment BlogLoopFragment on WpPage_Flexiblecontent_FlexibleContent_BlogLoop {
    fieldGroupName
    variation
    extraClass
  }
`;
