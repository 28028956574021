import { Link, graphql } from 'gatsby';
import parse from 'html-react-parser';
import lunr from 'lunr';
import React, { useEffect, useState } from 'react';
import { MagnifyingGlass } from 'react-loader-spinner';

const Search = (props) => {
  const imageFixLimit = 6;
  const [imageLimit, setImageLimit] = useState(imageFixLimit);
  const [type, setType] = useState('all');
  const [gallery, setGallery] = useState([]);
  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [params, setParams] = useState('');
  const [idx, setIdx] = useState(null);
  let paramsQuery = '';
  if (typeof window !== 'undefined') {
    paramsQuery = new URL(window.location.href).searchParams.get('q');
  }
// Function to handle special cases for search terms
const handleSpecialCases = (query) => {
  const specialCases = ['terms and conditions', 'terms and'];
  const normalizedQuery = query.toLowerCase().replace(/\s+/g, '');

  const normalizedSpecialCases = specialCases.map(caseItem => caseItem.replace(/\s+/g, ''));

  if (normalizedSpecialCases.includes(normalizedQuery)) {
    return 'terms';
  }

  return query;
};

  useEffect(() => {
    setParams(handleSpecialCases(paramsQuery));
  }, [paramsQuery]);

  useEffect(() => {
    if (params) {
      fetchData();
    }
  }, [params]);

  useEffect(() => {
    if (results) {
      const idx = getIndex(results);
      setIdx(idx);
    }
  }, [results]);

  let lunrResults = [];
  if (idx && results) {
    // the following will match all documents with words beginning with query:
    // https://lunrjs.com/guides/searching.html
    lunrResults = idx.search(
      `title:${params}^2 body:${params}^5 ${params}~1 type:hotel-lodges^2 type:blogs^1.5 type:itineraries^1 -type:press`
    );
  }

  const fetchData = () => {
    setIsLoading(true);
    document.body.classList.add('loading');
    const url = `https://wp.theroyalportfolio.com/wp-json/wp/v2/searchResults`;
    fetch(url)
      .then((response) => response.json())
      .then((resultsData) => {
        const filterResult = resultsData.filter(
          (item) =>
            item.post_title !== 'Royal Members Club' &&
            item.post_title !== 'Rate Request Form' &&
            item.post_title !== 'Rates' &&
            item.post_type !== 'in-the-media' &&
            item.post_type !== 'accommodation' &&
            item.post_type !== 'guides' &&
            item.post_type !== 'testimonials' &&
            !item.pathname.includes('overview') &&
            item.searchData.join('')
        );
        setResults(filterResult);
        document.body.classList.remove('loading');
        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Error', error);
        setIsLoading(false);
      });
  };

  function getIndex(results) {
    return lunr(function () {
      this.pipeline.remove(lunr.stemmer);
      this.ref('id');
      this.field('title');
      this.field('category');
      this.field('body');
      this.field('type');

      results.forEach((result) => {
        const searchData = result.searchData
          .join('')
          .toString()
          .replace(/<[^>]*>?/gm, '');
        this.add({
          id: result.ID,
          title: result.post_title,
          category: result.getCat.name,
          body: searchData,
          type: result.post_type,
        });
      });
    });
  }

  const handleFilterData = (type) => {
    setType(type);
    setImageLimit(imageLimit);
    lunrResults =
      type === 'all'
        ? lunrResults
        : lunrResults.filter((item) => item.getCat.name === type);
  };

  return (
    <>
      {isLoading && (
        <div className="loader-spinner">
          <MagnifyingGlass
            visible={true}
            height="80"
            width="80"
            ariaLabel="MagnifyingGlass-loading"
            wrapperStyle={{}}
            wrapperClass="MagnifyingGlass-wrapper"
            glassColor="#FFFFFF"
            color="#B09244"
          />
          {params && (
            <h4 className="text-24">
              Please wait while we retrieve your results for "
              {params.toUpperCase()}"
            </h4>
          )}
        </div>
      )}
      {!isLoading && (
        <>
          <section
            className={`section-title lg:p-40 py-20 smscreen2:pb-20 ${module.extraClass}`}
          >
            <div className="fade-ani px-30 text-center">
              <div className="title-black text-center">
                {!isLoading && (
                  <span className="text-16 font-400 text-dark uppercase">
                    {lunrResults.length} Results for
                  </span>
                )}

                {!isLoading && params && <h2>"{params.toUpperCase()}"</h2>}
                {module.subheading && <p>{module.subheading}</p>}
              </div>
            </div>
          </section>
          <section className={`img-grid portrait lg:pb-60 pb-30`}>
            <div className="container-fluid">
              <div className="flex flex-wrap lg:mx-minus-20 lg:gap-y-20 gap-y-10 ">
                {lunrResults.slice(0, imageLimit).map((o) => {
                  // Filter through all of the graphql data provided by the parent component
                  // to find the ID which matches the results provided by Lunr
                  // and just grab the first and only item (it'll be a single item array)
                  const item = results.filter(
                    (result) => result.ID.toString() === o.ref
                  )[0];

                  // content can potentially be long. if it exceeds our contentCharLimit
                  // we cap it, and append a ...

                  return (
                    <div
                      key={item.ID}
                      className={`lg:w-4/12 w-full lg:px-20 fade-img`}
                    >
                      <div className="img">
                        {item.featuredImg ? (
                          <Link
                            to={item.pathname.replace(
                              'https://wp.theroyalportfolio.com/',
                              '/'
                            )}
                          >
                            <img src={item.featuredImg} alt="search image" />
                          </Link>
                        ) : (
                          <Link
                            to={item.pathname.replace(
                              'https://wp.theroyalportfolio.com/',
                              '/'
                            )}
                          >
                            <img
                              src="../../images/banner.jpg"
                              alt="search image"
                            />
                          </Link>
                        )}
                      </div>

                      <div className="img-content pt-30 max-w-540">
                        {item.post_type === 'hotel-lodges' && (
                          <span className="text-13 text-dark font-400 uppercase leading-22 mb-10 inline-block font-preheaders">
                            Hotel & Lodges
                          </span>
                        )}
                        {item.post_type === 'itineraries' && (
                          <span className="text-13 text-dark font-400 uppercase leading-22 mb-10 inline-block font-preheaders">
                            Itineraries
                          </span>
                        )}
                        {item.post_type === 'post' && (
                          <span className="text-13 text-dark font-400 uppercase leading-22 mb-10 inline-block font-preheaders">
                            Blogs
                          </span>
                        )}
                        {item.post_type === 'experiences' && (
                          <span className="text-13 text-dark font-400 uppercase leading-22 mb-10 inline-block font-preheaders">
                            Experiences
                          </span>
                        )}

                        {item.post_type === 'page' && (
                          <span className="text-13 text-dark font-400 uppercase leading-22 mb-10 inline-block font-preheaders">
                            The Royal Portfolio
                          </span>
                        )}

                        {item.post_type === 'press' && (
                          <span className="text-13 text-dark font-400 uppercase leading-22 mb-10 inline-block font-preheaders">
                            Press
                          </span>
                        )}

                        {item.post_type === 'foundation' && (
                          <span className="text-13 text-dark font-400 uppercase leading-22 mb-10 inline-block font-preheaders">
                            {item.getCat[0].name} | Foundation
                          </span>
                        )}

                        {item.post_type === 'private-residences' && (
                          <span className="text-13 text-dark font-400 uppercase leading-22 mb-10 inline-block font-preheaders">
                            Private Residences
                          </span>
                        )}

                        <h4 className="text-black-200">{item.post_title}</h4>

                        <div className="content search-content text-sm mt-15 mb-5">
                          {parse(
                            item?.post_content ||
                              item?.post_excerpt ||
                              item?.searchData[0]
                          )}
                        </div>

                        {item.pathname && (
                          <Link
                            to={item.pathname.replace(
                              'https://wp.theroyalportfolio.com/',
                              '/'
                            )}
                            className="link-btn cursor-pointer"
                          >
                            Explore
                          </Link>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
              {lunrResults.length > imageLimit && !isLoading && (
                <div className="btn-custom justify-center text-center cursor-pointer lg:mt-80 mt-40">
                  <a
                    onClick={() => {
                      setImageLimit((imageLimit) => imageLimit + imageFixLimit);
                    }}
                    className="button button-transparent bg-[#e2e2e2]"
                  >
                    Load More
                  </a>
                </div>
              )}
            </div>

            {lunrResults.length === 0 && !isLoading && (
              <div className="no-record-found">
                <div className="container-fluid-md">
                  <div className="bg-white shadow-card lg:py-120 py-60">
                    <div className="text-center">
                      <h2>No Search Found</h2>
                      <div className="content mt-20 max-w-500 m-auto">
                        <p>
                          There don't seem to be any search result at the
                          moment. please check in again a later date.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </section>
        </>
      )}
    </>
  );
};

export default Search;

export const SearchFragment = graphql`
  fragment SearchFragment on WpPage_Flexiblecontent_FlexibleContent_Search {
    # content
    fieldGroupName
  }
`;
