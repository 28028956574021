import { graphql, Link } from 'gatsby';
import parse from 'html-react-parser';
import React from 'react';

const PressLoop = ({ module }) => {
  const data = module?.press || [];
  return (
    <>
      <section
        className={`img-grid portrait lg:py-60 py-30 ${module.extraClass}`}
      >
        <div className="container-fluid">
          <div className="flex flex-wrap lg:mx-minus-20 lg:gap-y-20 gap-y-10 ">
            {data &&
              data.map((item, index) => {
                return (
                  <div
                    className={`lg:w-6/12 w-full lg:px-20 fade-img`}
                    key={item?.id || index.toString()}
                  >
                    <img
                      src={item.featuredImage?.node.imgixImage.fluid.srcWebp}
                      alt={item.featuredImage?.node.altText}
                      className="w-full object-cover"
                      width="890"
                      height="647"
                      loading="lazy"
                    />
                    <div className="img-content pt-30 max-w-540">
                      {item?.preheading && (
                        <span className="text-13 text-dark font-400 uppercase leading-22 mb-10 inline-block font-preheaders">
                          {item.preheading}
                        </span>
                      )}
                      <h4 className="text-black-200"> {item.title}</h4>
                      {item?.subheading && (
                        <span className="uppercase text-dark text-11 font-400 font-preheaders">
                          {item.subheading}
                        </span>
                      )}
                      {item.description && (
                        <div className="content text-sm my-15">
                          {item.description && parse(item.description)}
                        </div>
                      )}
                      <Link to={item.uri} className="link-btn">
                        EXPLORE
                      </Link>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </section>
    </>
  );
};

export default PressLoop;

export const PressLoopFragment = graphql`
  fragment PressLoopFragment on WpPage_Flexiblecontent_FlexibleContent_PressLoop {
    fieldGroupName
    press {
      ... on WpPress {
        id
        featuredImage {
          node {
            altText
            imgixImage {
              fluid(
                maxWidth: 890
                maxHeight: 647
                imgixParams: { w: 890, h: 647, fit: "crop" }
              ) {
                srcWebp
              }
            }
            title
            uri
          }
        }
        title
        uri
      }
    }
  }
`;
