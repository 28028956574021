import { addDays, parseISO } from 'date-fns';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const StayWithUs = ({ module }) => {
  const [adults, setAdults] = useState(12);
  const [children, setChildren] = useState(4);
  const [url, setUrl] = useState('');
  var date = new Date();
  const [startDate, setStartDate] = useState(date.setDate(date.getDate() + 1));
  const [nextDate, setnextDate] = useState(date.setDate(date.getDate() + 1));

  const handleScroll = () => {
    var ele_property = document
      .querySelector('#bottom-footer')
      .getBoundingClientRect();
    if (window.pageYOffset > 100) {
      document.body.classList.add('scroll-down');
    }
    if (window.pageYOffset <= 100) {
      document.body.classList.remove('scroll-down');
    }
    if (
      ele_property.top < window.innerHeight + 800 &&
      ele_property.bottom >= 0
    ) {
      document.getElementById('StayFooter').style.display = 'none';
    } else {
      document.getElementById('StayFooter').style.display = 'block';
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const [formData, setFormData] = useState({
    property: '',
    arrival: '',
    departure: '',
    guestAdult: '',
    guestChild: '',
  });
  const [errorData, setErrorData] = useState({
    property: false,
    arrival: false,
    departure: false,
    guestAdult: false,
    guestChild: false,
  });

  const onChangeFormData = (e) => {
    const property = e.target.value;
    switch (property) {
      case 'laresidence':
        setAdults(4);
        setChildren(2);
        break;
      case 'thesilo':
        setAdults(4);
        setChildren(2);
        break;
      case 'birkenheadhouse':
        setAdults(2);
        setChildren(0);
        break;
      case 'royalmalewane':
        setAdults(12);
        setChildren(4);
        break;
    }

    setUrl(
      'https://bookings.theroyalportfolio.com/' + property + '/book/dates'
    );

    setFormData((previousState) => {
      return {
        ...previousState,
        property: property,
      };
    });
    setErrorData((previousState) => {
      return {
        ...previousState,
        property: property === '' ? true : false,
      };
    });
  };

  const onChkForm = (e) => {
    let formValid = false;
    Object.entries(formData).map((item, key) => {
      const isError = item[1] === '' ? true : false;
      if (isError && !formValid) {
        formValid = true;
      }
      setErrorData((previousState) => {
        return {
          ...previousState,
          [`${item[0]}`]: isError,
        };
      });
    });
    if (formValid) {
      e.preventDefault();
    }
  };
  return (
    <>
      <section className="stay-with-us lg:py-40 py-20 bg-white" id="StayFooter">
        <div className="container-fluid">
          <div className="flex flex-wrap items-center justify-between">
            <div className="left-title lg:w-10-per laptop:w-[15%] lgscreen:w-auto">
              <h6 className="text-20  text-black-400 font-400 relative lg:pr-50 desktop2:pr-10 ">
                Stay with us
              </h6>
            </div>
            <form
              action={url}
              method="post"
              className="w-[85%] lgscreen:w-auto block lgscreen:hidden"
              id="form"
            >
              {formData.property === 'royalmalewane' && (
                <input
                  type="hidden"
                  id="royalportfolio_royalmalewane_book_dates"
                  name="royalportfolio_royalmalewane_book_dates"
                  value="1"
                />
              )}
              {formData.property === 'thesilo' && (
                <input
                  type="hidden"
                  id="royalportfolio_thesilo_book_dates"
                  name="royalportfolio_thesilo_book_dates"
                  value="1"
                />
              )}
              {formData.property === 'laresidence' && (
                <input
                  type="hidden"
                  id="royalportfolio_laresidence_book_dates"
                  name="royalportfolio_laresidence_book_dates"
                  value="1"
                />
              )}
              {formData.property === 'birkenheadhouse' && (
                <input
                  type="hidden"
                  id="royalportfolio_birkenheadhouse_book_dates"
                  name="royalportfolio_birkenheadhouse_book_dates"
                  value="1"
                />
              )}

              <input type="hidden" id="continue" name="continue" value="1" />
              <div className="sw-info lg:w-full lgscreen:w-auto smscreen2:w-auto laptop:pl-20 lg:pl-50 xlscreen:pl-0 ">
                <div className="flex lg:gap-x-10 desktop2:gap-8 laptop:gap-5 xlscreen:gap-3 items-center xsscreen3:inline-block xsscreen3:space-y-0 xsscreen3:w-full ">
                  <div className="lg:w-2/12 w-full lg:block hidden">
                    <div className="sw-info-select relative">
                      <select
                        name=""
                        onChange={onChangeFormData}
                        value={formData.property}
                        placeholder="Hotel Or Lodge"
                        id=""
                        className="rm"
                      >
                        <option value="" disabled hidden>
                          Hotel Or Lodge
                        </option>

                        <option value="royalmalewane">Royal Malewane</option>
                        <option value="thesilo">The Silo Hotel</option>
                        <option value="laresidence">La Residence</option>
                        <option value="birkenheadhouse">
                          Birkenhead House
                        </option>
                      </select>
                    </div>
                    {errorData.property && (
                      <label className="text-12 text-[#ff0000]">
                        Please select hotel or lodge
                      </label>
                    )}
                  </div>
                  <div className="lg:w-2/12 w-full lg:block hidden">
                    <div className="sw-info-select relative">
                      <DatePicker
                        className="placeholder:text-dark placeholder:font-400 placeholder:uppercase placeholder:text-11 placeholder:tracking-0.01 outline-none"
                        selected={formData.arrival}
                        placeholderText="Arrival"
                        id="arrival"
                        minDate={startDate}
                        name="arrival"
                        onChange={(date) => {
                          setFormData((previousState) => {
                            return {
                              ...previousState,
                              arrival: date,
                              departure: '',
                            };
                          });
                          setErrorData((previousState) => {
                            return {
                              ...previousState,
                              arrival: date === '' ? true : false,
                            };
                          });
                        }}
                      />
                    </div>
                    {errorData.arrival && (
                      <label className="text-12 text-[#ff0000]">
                        Please select arrival
                      </label>
                    )}
                  </div>
                  <div className="lg:w-2/12 w-full lg:block hidden">
                    <div className="sw-info-select relative">
                      <DatePicker
                        className="placeholder:text-dark placeholder:font-400 placeholder:uppercase placeholder:text-11 placeholder:tracking-0.01 outline-none"
                        selected={formData.departure}
                        minDate={addDays(parseISO(formData.arrival), 1)}
                        id="departure"
                        name="departure"
                        placeholderText="Departure"
                        onChange={(date) => {
                          setFormData((previousState) => {
                            return {
                              ...previousState,
                              departure: date,
                            };
                          });
                          setErrorData((previousState) => {
                            return {
                              ...previousState,
                              departure: date === '' ? true : false,
                            };
                          });
                        }}
                      />
                    </div>
                    {errorData.departure && (
                      <label className="text-12 text-[#ff0000]">
                        Please select departure
                      </label>
                    )}
                  </div>

                  <div className="lg:w-2/12 w-full lg:block hidden">
                    <div className="sw-info-select relative">
                      <select
                        name="guestAdult"
                        value={formData.guestAdult}
                        onChange={(e) => {
                          setFormData((previousState) => {
                            return {
                              ...previousState,
                              guestAdult: e.target.value,
                            };
                          });
                          setErrorData((previousState) => {
                            return {
                              ...previousState,
                              guestAdult: e.target.value === '' ? true : false,
                            };
                          });
                        }}
                        id="guestAdult"
                      >
                        <option value="">Select Adults</option>
                        {Array.apply(null, { length: adults }).map((e, i) => (
                          <option key={i} value={i + 1}>
                            {i + 1} Adults
                          </option>
                        ))}
                      </select>
                    </div>
                    {errorData.guestAdult && (
                      <label className="text-12 text-[#ff0000]">
                        Please select Adults
                      </label>
                    )}
                  </div>
                  <div className="lg:w-2/12 w-full lg:block hidden">
                    <div className="sw-info-select relative">
                      <select
                        name="guestChild"
                        value={formData.guestChild}
                        onChange={(e) => {
                          setFormData((previousState) => {
                            return {
                              ...previousState,
                              guestChild: e.target.value,
                            };
                          });

                          setErrorData((previousState) => {
                            return {
                              ...previousState,
                              guestChild: e.target.value === '' ? true : false,
                            };
                          });
                        }}
                        id="guestChild"
                      >
                        <option value="">Select Children</option>
                        {Array.apply(null, { length: children + 1 }).map(
                          (e, i) => (
                            <option key={i} value={i}>
                              {i} Children
                            </option>
                          )
                        )}
                      </select>
                    </div>
                    {errorData.guestChild && (
                      <label className="text-12 text-[#ff0000]">
                        Please select Children
                      </label>
                    )}
                  </div>
                  <div className="lg:w-2/12 w-full lg:block">
                    <div className="btn-custom w-full">
                      <input
                        value="Check Availability"
                        onClick={onChkForm}
                        type="submit"
                        className="button button-transparent cursor-pointer w-full text-center inline-block"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <div className="lgscreen:block mobile-availability hidden">
              <a
                href="https://bookings.theroyalportfolio.com/"
                target="_blank"
                className="button cursor-pointer button-transparent w-full text-center inline-block"
              >
                Check Availability
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default StayWithUs;
