import { graphql } from 'gatsby';
import parse from 'html-react-parser';
import React, { useState } from 'react';
import 'react-accessible-accordion/dist/fancy-example.css';
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

const ContactTabs = ({ module }) => {
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }

  const [tabOption, setTabOption] = useState(
    module.tabs ? module.tabs[0].tabTitle : ''
  );

  const onTabChange = (value, index) => {
    setTabOption(value);
    const swiper = document.querySelector('.contactSlider').swiper;
    swiper.slideTo(index, 1000, false);
  };

  const handleNextClickStay = () => {
    const swiper = document.querySelector('.imgBox-slider .swiper').swiper;
    swiper.slideNext();
  };
  const handlePrevClickStay = () => {
    const swiper = document.querySelector('.imgBox-slider .swiper').swiper;
    swiper.slidePrev();
  };

  const onSlideChange = (e) => {
    setTabOption(module.tabs[e.realIndex]?.tabTitle);
  };

  return (
    <>
      <section
        className={`zigzag lg:pb-60 pb-30 zigzag-tabs lg:mb-60 mb-30  ${module.extraClass}`}
      >
        <div className="container-fluid-md">
          <div className="bg-white lg:py-60 py-30 pb-60 shadow-card lg:pl-120 pl-0 smscreen:!px-20">
            <div className="tab-title text-center">
              <div className="title-black">
                <h2>{module.heading}</h2>
              </div>
              <div className="zigzag-tab max-w-700 m-auto  mb-50 mt-20">
                <ul className="tabs justify-center  mt-20 mb-30 mdscreen:hidden inline-block">
                  {module.tabs &&
                    module.tabs.map((item, i) => {
                      return (
                        <li
                          key={i}
                          onClick={() => onTabChange(item.tabTitle, i)}
                          className={`tab-link cursor-pointer text-dark uppercase font-400 text-12 font-preheaders ${
                            tabOption == item.tabTitle ? 'tab-current' : ''
                          }`}
                        >
                          {item.tabTitle && parse(item.tabTitle)}
                        </li>
                      );
                    })}
                </ul>

                <select
                  className="outline-none bg-white text-left w-full hidden mdscreen:block appearance-none border-b-1 pb-10 border-dark border-opacity-40"
                  id="contact-filter-drop-down"
                  onChange={(e) => {
                    const select = document.getElementById(
                      'contact-filter-drop-down'
                    );
                    onTabChange(e.target.value, select.selectedIndex);
                  }}
                >
                  {module?.tabs?.length > 0 &&
                    module.tabs.map((item, i) => {
                      return (
                        <option key={i} value={item.tabTitle}>
                          {item.tabTitle}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>
            <div className="flex flex-wrap">
              <div
                className={`lg:w-6/12 w-full relative ${
                  'Right' === module.imagePosition ? ' lg:order-1' : ''
                }`}
              >
                <Swiper
                  className="contactSlider !h-[500px] smscreen:!h-[250px]"
                  loop={false}
                  navigation={true}
                  modules={[Navigation, Pagination]}
                  pagination={{
                    type: 'progressbar',
                  }}
                  onSlideChange={onSlideChange}
                >
                  {module.tabs?.length > 0 &&
                    module.tabs.map((item, i) => {
                      return (
                        <SwiperSlide key={i} className="!h-[500px]">
                          <img
                            src={item?.tabImage.imgixImage.fluid.srcWebp}
                            alt={item?.tabImage.altText}
                            width="720"
                            height="500"
                            className="w-full object-cover h-full"
                            loading="lazy"
                          />
                        </SwiperSlide>
                      );
                    })}
                </Swiper>

                {module?.fullVideo && module?.fullVideo !== '' && (
                  <div className="inline-block cursor-pointer mt-15 absolute top-50-per left-50-per translate-x-minus_50 translate-y-minus_50">
                    <button
                      onClick={() => {
                        openModal();
                      }}
                      className="inline-block"
                    >
                      {/* <img src="../images/play-icon.png" alt="" /> */}
                    </button>
                  </div>
                )}
              </div>
              <div className="lg:w-6/12 w-full">
                <div className="zigzag-content lg:text-left text-center fade-ani">
                  {module.tabs &&
                    module.tabs.map((item, i) => {
                      return (
                        item.tabTitle === tabOption && (
                          <div
                            key={i}
                            id="overview"
                            className="tab-content global-list"
                          >
                            {item?.heading !== '' && (
                              <div className="title-black mb-20 lg:pr-100">
                                <h4>{item.heading}</h4>
                              </div>
                            )}

                            {item.content && (
                              <div className="content">
                                {parse(item.content)}
                              </div>
                            )}
                            <div className="btn-custom lg:mt-25 mt-15">
                              {item.cta && (
                                <a
                                  href={item.cta.url}
                                  className="button button-transparent"
                                  target="_blank"
                                >
                                  {item.cta.title}
                                </a>
                              )}
                            </div>
                            <div className="flex pt-20 gap-x-3 items-center lgscreen:justify-center">
                              {item.socialIcons &&
                                item.socialIcons.map((item1, i) => {
                                  return (
                                    <div key={i} className="s-icon">
                                      <a href={item1.socialLink}>
                                        <img
                                          src={item1.socialIcon.mediaItemUrl}
                                          className="without-hover"
                                        />
                                        <img
                                          src={
                                            item1.socialIconHover.mediaItemUrl
                                          }
                                          className="with-hover "
                                        />
                                      </a>
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        )
                      );
                    })}
                </div>
              </div>
            </div>
            <div className="fade-ani px-30 pt-50 text-center">
              <div className="title-black text-center">
                <h3>Press & Media Enquiries</h3>
              </div>
              <div className="content max-w-[650px] m-auto text-md text-center my-10">
                For press and media enquiries please email:{' '}
                <a className="text-gold" href="mailto:press@trp.travel">
                  press@trp.travel
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactTabs;
export const ContactTabsFragment = graphql`
  fragment ContactTabsFragment on WpPage_Flexiblecontent_FlexibleContent_ContactTabs {
    extraClass
    fieldGroupName
    variation
    tabs {
      content
      cta {
        target
        title
        url
      }
      fieldGroupName
      heading
      socialIcons {
        fieldGroupName
        socialIcon {
          altText
          uri
          title
          mediaItemUrl
        }
        socialIconHover {
          altText
          uri
          title
          mediaItemUrl
        }
        socialLink
      }
      subheading
      tabImage {
        altText
        mediaItemUrl
        imgixImage {
          fluid(
            maxWidth: 720
            maxHeight: 500
            imgixParams: { w: 720, h: 500, fit: "crop" }
          ) {
            srcWebp
          }
        }
        title
        uri
      }
      tabTitle
    }
    subheading
    preheading
    imagePosition
    heading
    gallery {
      altText
      uri
      title
      mediaItemUrl
    }
    cta {
      target
      title
      url
    }
    additionalCta {
      target
      title
      url
    }
  }
`;
