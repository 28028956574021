import { graphql, Link, useStaticQuery } from 'gatsby';
import parse from 'html-react-parser';
import React, { useState } from 'react';

const WhatsOnListing = ({ module, microSitename }) => {
  const blogLoopData = useStaticQuery(graphql`
    query WhatsOn {
      blogPostCategories: allWpCategory {
        nodes {
          name
          id
          slug
        }
      }
      blogPostInterest: allWpBlogInterest {
        nodes {
          name
          id
          slug
        }
      }
      blogPostEventMonth: allWpEventMonth {
        nodes {
          name
          id
          slug
        }
      }
      posts: allWpWhatsOn(sort: { fields: date, order: DESC }) {
        edges {
          node {
            categories {
              nodes {
                id
                name
                slug
              }
            }
            blogInterest {
              nodes {
                id
                name
                slug
              }
            }
            whatsonPostContent {
              eventDate
            }
            eventMonth {
              nodes {
                id
                slug
                name
              }
            }
            title
            uri
            excerpt
            featuredImage {
              node {
                altText
                imgixImage {
                  url(imgixParams: { w: 1340, h: 540, q: 95 })
                }
                title
              }
            }
            date(formatString: "DD MMMM YYYY")
          }
        }
      }
    }
  `);

  const blogCategories = blogLoopData?.blogPostCategories?.nodes || [];
  const blogInterest = blogLoopData?.blogPostInterest?.nodes || [];
  const blogEvent = blogLoopData?.blogPostEventMonth?.nodes || [];
  const posts = blogLoopData?.posts?.edges || [];
  const [postsToDisplay, setPostToDisplay] = useState(posts);
  const pageSize = 4;
  const [limit, setLimit] = useState(pageSize);
  const [barCat, setBarCat] = useState('all');
  const [barGuest, setBarGuest] = useState('all');
  const [postEvent, setPostEvent] = useState('all');

  const handleFilterData = (selectedCat, selectedGuest, selectedMonth) => {
    // all selected for 3 options
    if (
      selectedCat === 'all' &&
      selectedGuest === 'all' &&
      selectedMonth === 'all'
    ) {
      setPostToDisplay(posts);
    }
    // if property is selected
    if (
      selectedCat !== 'all' &&
      selectedGuest === 'all' &&
      selectedMonth === 'all'
    ) {
      const guestArray = posts.filter((item) => {
        const barCategorySlugs = item.node.categories.nodes.map(
          (node) => node.slug
        );
        const isCategoryMatch =
          !selectedCat || barCategorySlugs.includes(selectedCat);
        return isCategoryMatch;
      });
      setPostToDisplay(guestArray);
    }
    // if event is selected
    if (
      selectedGuest !== 'all' &&
      selectedCat === 'all' &&
      selectedMonth === 'all'
    ) {
      const catArray = posts.filter((item) => {
        const barGuestSlugs = item.node.blogInterest.nodes.map(
          (node) => node.slug
        );
        const isGuestMatch =
          !selectedGuest || barGuestSlugs.includes(selectedGuest);
        return isGuestMatch;
      });
      setPostToDisplay(catArray);
    }
    // if time is selected
    if (
      selectedMonth !== 'all' &&
      selectedCat === 'all' &&
      selectedGuest === 'all'
    ) {
      const catArray = posts.filter((item) => {
        const blogEventSlugs = item.node.eventMonth.nodes.map(
          (node) => node.slug
        );
        const isGuestMatch =
          !selectedMonth || blogEventSlugs.includes(selectedMonth);
        return isGuestMatch;
      });
      setPostToDisplay(catArray);
    }
    // if property && event is selected
    if (
      selectedMonth === 'all' &&
      selectedCat !== 'all' &&
      selectedGuest !== 'all'
    ) {
      const allArray = posts.filter((item) => {
        const barCategorySlugs = item.node.categories.nodes.map(
          (node) => node.slug
        );
        const barGuestSlugs = item.node.blogInterest.nodes.map(
          (node) => node.slug
        );
        const isCategoryMatch =
          !selectedCat || barCategorySlugs.includes(selectedCat);
        const isGuestMatch =
          !selectedGuest || barGuestSlugs.includes(selectedGuest);

        return isCategoryMatch && isGuestMatch;
      });
      setPostToDisplay(allArray);
    }
    // if property && when is selected
    if (
      selectedGuest === 'all' &&
      selectedCat !== 'all' &&
      selectedMonth !== 'all'
    ) {
      const allArray = posts.filter((item) => {
        const barCategorySlugs = item.node.categories.nodes.map(
          (node) => node.slug
        );
        const barGuestSlugs = item.node.eventMonth.nodes.map(
          (node) => node.slug
        );
        const isCategoryMatch =
          !selectedCat || barCategorySlugs.includes(selectedCat);
        const isGuestMatch =
          !selectedMonth || barGuestSlugs.includes(selectedMonth);

        return isCategoryMatch && isGuestMatch;
      });
      setPostToDisplay(allArray);
    }
    // if event && when is selected
    if (
      selectedCat === 'all' &&
      selectedGuest !== 'all' &&
      selectedMonth !== 'all'
    ) {
      const allArray = posts.filter((item) => {
        const barCategorySlugs = item.node.blogInterest.nodes.map(
          (node) => node.slug
        );
        const barGuestSlugs = item.node.eventMonth.nodes.map(
          (node) => node.slug
        );
        const isCategoryMatch =
          !selectedGuest || barCategorySlugs.includes(selectedGuest);
        const isGuestMatch =
          !selectedMonth || barGuestSlugs.includes(selectedMonth);

        return isCategoryMatch && isGuestMatch;
      });
      setPostToDisplay(allArray);
    }
    // if property && event && when is selected
    if (
      selectedCat !== 'all' &&
      selectedGuest !== 'all' &&
      selectedMonth !== 'all'
    ) {
      const allArray = posts.filter((item) => {
        const barCategorySlugs = item.node.categories.nodes.map(
          (node) => node.slug
        );
        const barGuestSlugs = item.node.blogInterest.nodes.map(
          (node) => node.slug
        );
        const blogEventSlugs = item.node.eventMonth.nodes.map(
          (node) => node.slug
        );
        const isCategoryMatch =
          !selectedCat || barCategorySlugs.includes(selectedCat);
        const isGuestMatch =
          !selectedGuest || barGuestSlugs.includes(selectedGuest);
        const isEventMatch =
          !selectedMonth || blogEventSlugs.includes(selectedMonth);
        return isCategoryMatch && isGuestMatch && isEventMatch;
      });
      setPostToDisplay(allArray);
    }
  };

  return (
    <>
      <section
        className={`img-grid portrait lg:py-60 py-30 whatsonListing blogLoop-slider ${module.extraClass}`}
      >
        <div className="container-fluid">
          <div className="post-list-filter pb-35">
            <div className="flex justify-between lg:gap-x-10 desktop2:gap-8 laptop:gap-5 xlscreen:gap-3 items-center xsscreen3:inline-block xsscreen3:space-y-0 w-full ">
              <div className="w-full flex flex-wrap gap-[50px] lgscreen:gap-5 smscreen2:gap-3 justify-center">
                <div className="w-2/12 smscreen2:w-full">
                  <div className="sw-info-select relative">
                    <select
                      value={barCat}
                      onChange={(e) => {
                        const selectedValue = e.target.value;
                        setBarCat(selectedValue);
                        handleFilterData(selectedValue, barGuest, postEvent);
                      }}
                    >
                      <option value="all">Select Property</option>
                      {blogCategories.map((item, i) => {
                        return (
                          <option key={i} value={item.slug}>
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="w-2/12 smscreen2:w-full">
                  <div className="sw-info-select relative">
                    <select
                      value={barGuest}
                      onChange={(e) => {
                        const selectedValue = e.target.value;
                        setBarGuest(selectedValue);
                        handleFilterData(barCat, selectedValue, postEvent);
                      }}
                    >
                      <option value="all">Select Category</option>
                      {blogInterest.map((item, j) => {
                        return (
                          <option key={j} value={item.slug}>
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="w-2/12 smscreen2:w-full">
                  <div className="sw-info-select relative">
                    <select
                      value={postEvent}
                      onChange={(e) => {
                        const selectedValue = e.target.value;
                        setPostEvent(selectedValue);
                        handleFilterData(barCat, barGuest, selectedValue);
                      }}
                    >
                      <option value="all">When</option>
                      {blogEvent.map((item, k) => {
                        return (
                          <option key={k} value={item.slug}>
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap lg:mx-minus-20 lg:gap-y-20 gap-y-10 ">
            {postsToDisplay.length > 0 &&
              postsToDisplay.slice(0, limit).map((item, index) => {
                return (
                  <div
                    key={index}
                    className={`lg:w-6/12 w-full lg:px-20 fade-img`}
                  >
                    <div className="img">
                      <Link to={item?.node.uri}>
                        <img
                          src={item?.node?.featuredImage?.node.imgixImage.url}
                          alt={item?.node?.featuredImage?.node?.altText || ''}
                          className={`object-cover w-full`}
                          loading="lazy"
                        />
                      </Link>
                    </div>
                    <div className="img-content pt-30 max-w-800">
                      <Link to={item?.node.uri}>
                        <span className="uppercase text-dark text-13 font-400 leading-24 font-body">
                          {item?.node.whatsonPostContent?.eventDate && (
                            <div>{item.node.whatsonPostContent.eventDate}</div>
                          )}{' '}
                        </span>
                        <h4 className="text-black-200 mt-10 mb-20 font-heading text-[28px] font-400 leading-[32px]">
                          {' '}
                          {item?.node.title}
                        </h4>
                        <span className="feature-list text-gold text-13 font-400 leading-24 font-body">
                          {item.node.blogInterest?.nodes?.length > 0 &&
                            item.node.blogInterest.nodes.map((intrest, j) => (
                              <span key={j}>{intrest.name}</span>
                            ))}
                        </span>
                        <div className="content text-sm mt-15 mb-30 max-w-[430px] lgscreen:max-w-full">
                          {item?.node.excerpt && parse(item?.node.excerpt)}
                        </div>
                      </Link>
                      <Link to={item?.node.uri} className="link-btn">
                        READ MORE
                      </Link>
                    </div>
                  </div>
                );
              })}
          </div>
          {postsToDisplay?.length > limit && (
            <div className="btn-custom text-center justify-center cursor-pointer lg:mt-80 mt-40">
              <a
                onClick={() => {
                  setLimit((prevLimit) => prevLimit + pageSize);
                }}
                className="button button-transparent bg-[#e2e2e2]"
              >
                Load More
              </a>
            </div>
          )}

          {postsToDisplay.length == 0 && (
            <div className="text-center justify-center lg:mt-80 mt-40 text-13 font-400 leading-24 font-body">
              Please select another options.
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default WhatsOnListing;

export const WhatsOnListingFragment = graphql`
  fragment WhatsOnListingFragment on WpPage_Flexiblecontent_FlexibleContent_WhatsOnListing {
    extraClass
    extraId
  }
`;
